import { useEffect, useState } from 'react';

/* eslint-disable */

const useForm = (formData = [], defaultValues) => {
  const [formState, setFormState] = useState({});
  const [errors, setErrors] = useState({});

  /* Llena el Form State con los Default Values */
  useEffect(() => {
    if (defaultValues) {
      setFormState(defaultValues);
    } else {
      let defaultValuesAux = {};
      formData?.forEach(
        ({ props: { name, defaultValue, submit, formData } }) => {
          if (!submit && !formData) defaultValuesAux[name] = defaultValue || '';
          if (name === 'without_address_number')
            defaultValuesAux[name] = defaultValue;
        }
      );
      setFormState(defaultValuesAux);
    }
  }, []);
  /* Llena el objeto de errores */
  const runValidations = () => {
    let errorsAux = {};
    let isValidForm = true;
    let firstElementError = null;
    formData?.forEach(({ props: { name, validations, required, submit } }) => {
      let isFully = typeof formState[name] == 'number' || !!formState[name];
      if ((required || validations?.length > 0) && !submit) {
        if (required) {
          if (!formState[name]) {
            errorsAux = { ...errorsAux, [name]: 'Campo obligatorio' };
            isValidForm = false;
            if (!firstElementError) firstElementError = name;
            return false;
          } else errorsAux = { ...errorsAux, [name]: '' };
        }

        if (validations?.length > 0 && isFully) {
          for (let i = 0; i < validations?.length; i++) {
            const { isValid, errorMessage } = validations[i](formState[name]);

            if (isValid) {
              errorsAux = { ...errorsAux, [name]: '' };
            } else {
              errorsAux = {
                ...errorsAux,
                [name]: errorMessage || 'Campo incorrecto'
              };
              if (!firstElementError) firstElementError = name;
              isValidForm = false;
              break;
            }
          }
        } else {
          return true;
        }
      }
      // Si no tiene validación ni es requerido y vacio o es un boton, entonces es válido
      return true;
    });
    setErrors(errorsAux);
    document
      .getElementsByName(firstElementError)[0]
      ?.scrollIntoView({ block: 'center', behavior: 'smooth' });
    return isValidForm;
  };

  /* Handle Change */
  const onFieldChange = (target, name) => {
    setFormState((prevForm) => ({
      ...prevForm,
      [name]: target?.value
    }));
    if (errors[name]) setErrors({ ...errors, [name]: '' });
    if (name === 'cardNumber') {
      // verifica las validation del input 'cardNumber' con cada onChange
      formData?.forEach(
        ({ props: { name, validations, required, submit } }) => {
          if (name === 'cardNumber') {
            const { isValid, errorMessage } = validations[0](target.value);
            if (!isValid) setErrors({ ...errors, cardNumber: errorMessage });
          }
        }
      );
    }
    // el checkbox withOutNumber se trabaja de forma independiente poque modifica otro campo del formulario
    if (name === 'without_address_number') {
      if (target.checked) {
        setFormState({
          ...formState,
          without_address_number: true,
          number: ''
        });
        setErrors({ ...errors, number: '' });
      } else {
        setFormState({ ...formState, without_address_number: false });
      }
    }
  };

  /* Handle Submit */
  const handleUseFormSubmit = (e, onSubmit) => {
    e?.preventDefault();
    const isValidForm = runValidations();
    isValidForm && onSubmit();
  };

  return {
    onFieldChange,
    handleUseFormSubmit,
    errors,
    formState,
    setErrors
  };
};

export default useForm;
